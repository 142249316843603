.loading-spinner {
    height: 1.5rem;
    width: 1.5rem;
    border: 2px solid #d1d5db;
    border-top-color: #ff1a75;
    border-radius: 50%;
    animation: spinner 800ms linear infinite;
}

@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}