:global(body) {
    margin: 0;
}

:global(#root) {
    min-height: 100vh;
}

:global(.e-grid.e-dragclone) {
    z-index: 100 !important;
    background: red !important;
    border: 1px solid red !important;
}

:global(.hidden-input) {
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    left: -999999px;
}
