/* Timeline Day, Timeline Week, Timeline Work Week */
.e-work-cells.e-work-hours.holidayColor
{
    background-color: rgba(141, 240, 12, 0.5) !important;
}

/* Timeline Month */
.e-work-cells.e-child-node.e-work-days.holidayColor
{
    background-color: rgba(141, 240, 12, 0.5) !important; 
}

/* Text with highlighted font*/
.highlighted-text
{
    color: brown;
    font-weight: bold;
}

.new-attachment-names
{
    color: green;
    font-weight: bold;
}

.info-text
{
    color: lightslategray;
    font-weight: bold;
}

/*QuickInfoPopUp*/
.fixed-height-scrollbar-quickinfo-popup{
    max-height: 35rem;
    overflow-y: scroll;
    min-width: 32rem;
}
.e-quick-popup-wrapper{
    min-width: 35rem;
}

/*Expand button*/
.expand-button-class{
    font: inherit;
    color: darkcyan;
    font-weight: bold;
}

/*Shrink button*/
.shrink-button-class{
    font: inherit;
    color: brown;
    font-weight: bold;
}
