.list {
    width: 100%;
}

.calendar {
    height: 100vh;
}

.panel {
    overflow: hidden !important;
}
