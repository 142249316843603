.custom-work-days.e-schedule .e-month-view .e-work-days .holidayColor,
.custom-work-days.e-schedule .e-vertical-view .e-work-hours .holidayColor {
    background-color: rgba(141, 240, 12, 0.5);
}

/* Timeline Day */
.e-work-cells.e-alternate-cells.e-work-hours.holidayColor,
.e-work-cells.e-work-hours.holidayColor{
    background-color: rgba(141, 240, 12, 0.5);
}

/* Timeline Week */
.e-work-cells.holidayColor,
.e-work-cells.e-alternate-cells.holidayColor{
    background-color: rgba(141, 240, 12, 0.5);
}

/* Timeline Work Week */
.e-work-cells .e-work-hours .holidayColor{
    background-color: rgba(141, 240, 12, 0.5);
}

/* Timeline Month */
.e-work-cells .e-alternate-cells .e-work-hours .holidayColor,
.e-work-cells .e-child-node .e-work-days .holidayColor{
    background-color: rgba(141, 240, 12, 0.5);
}